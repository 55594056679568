import { toast } from 'react-toastify';

import ToastContent, { GlobalCableToastContent } from './components/ToastContent';
import ToastContainer from './components/ToastContainer';

/**
 * Usage:
 * 1. Add ToastContainer to your root page file
 * 2. Done! You can use toasts anywhere on this page (ex: toast.success("Done!");)
 *
 * Available types of toast: [info, success, warning, error, default]
 *
 * Important Note: Remember to render the ToastContainer once in the application tree!
 * To enable multiple container support see API reference here:
 * https://fkhadra.github.io/react-toastify/multi-containers
 */

toast.configure({
  position: toast.POSITION.BOTTOM_LEFT,
  autoClose: 10000,
  hideProgressBar: true,
  closeOnClick: false,
  theme: 'colored',
  icon: false,
});

export {
  toast,
  ToastContent,
  GlobalCableToastContent,
  ToastContainer,
};
